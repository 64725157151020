// src/utils/BannersAPI.js

import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BannersAPI = {
    getBanners: async () => {
        try {
            const response = await axios.get(`${BASE_URL}/banners.php`);
            return response.data;
        } catch (error) {
            console.error('Error fetching banners:', error);
            return [];
        }
    },
};

export default BannersAPI;
