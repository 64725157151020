import * as S from "../../../styles/GlobalStyles";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <S.Footer>
            <S.Container>
                <S.SiteNavFooter>
                    <div>
                        <h4>Contato</h4>
                        <ul style={{marginTop: '.4rem'}}>                            
                            <li>Endereço: Avenida, R. Sandro Antônio Mendes, 717 - Parque Vitoria Regia, Sorocaba - SP, 18078-535</li>
                            <li>Telefone: (15) 3226-2491</li>                            
                            <li>E-mail: contato@rodriguessi.com.br</li>
                        </ul>

                        <hr style={{margin: '.8rem 0'}} />

                        <h4>Redes sociais</h4>
                        <ul style={{marginTop: '.4rem'}}>                            
                            <li>WhatsApp:(15) 97406-5731</li>
                            <li><a target="_blank" href="https://www.instagram.com/ro.drigues505">Instagram</a></li>
                            <li><a target="_blank" href="https://www.facebook.com/profile.php?id=61556678333154">Facebook</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li><Link to={`/post/82`}>Termos e consentimentos</Link></li>                             
                            <li>© 2024 - Todos os direitos reservados</li>
                            <li>MICTMR</li>
                        </ul>
                    </div>
                </S.SiteNavFooter>
            </S.Container>
        </S.Footer>
    )
}

export default Footer;
