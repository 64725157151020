import React from 'react';
import { Viewport } from '../styles/GlobalStyles';
import Footer from './design/organisms/Footer';


function Layout({ children }) {
  return (
    <Viewport>
      {children}
      <Footer />
    </Viewport>
  );
}

export default Layout;
