import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ArticlesAPI from '../../../utils/ArticlesAPI';
import * as S from '../../../styles/GlobalStyles';
import PostCard from '../../design/molecules/PostCard';
import Header from '../../design/organisms/Header';

function ArticlesList() {
  const { id } = useParams();
  const [articles, setArticles] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL_IMAGES;
  const banners = [{ filename: "64eca15242b17.jpg"}]

  useEffect(() => {
    // Fetch articles from the backend API based on the sectionId passed in the URL
    const fetchArticles = async () => {
      const articlesData = await ArticlesAPI.getArticlesBySectionId(id);
      setArticles(articlesData);
    };

    fetchArticles();
  }, [id]);

  return (
    <>
      <Header banner={banners}
      />

      <S.SiteMain>
        <S.Container>
          <S.PostFeed>
            {articles.map((article, index) => (
              <div key={index}>
                <PostCard
                  imageUrl={BASE_URL+article.coverImage}
                  title={article.title}
                  content={article.content}
                  publicationDate={article.publicationDate}
                  link={`/post/${article.id}`}
                />
              </div>
            ))}
          </S.PostFeed>
        </S.Container>
      </S.SiteMain>
    </>
  );
}

export default ArticlesList;
