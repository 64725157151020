import * as S from "../../../styles/GlobalStyles";
import SiteBanner from "../molecules/SiteBanner";
import SiteMast from "../molecules/SiteMast";
import SiteNav from "../molecules/SiteNav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Header = (props) => {
  const banner = props.banner;
  const BASE_URL = process.env.REACT_APP_BASE_URL_IMAGES;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>

      <Slider {...settings}>
        {banner.map((item, index) => {
          return (
            <S.Header
              key={index}
              bg={BASE_URL+item.filename}
            >
              <S.Container>
                <SiteMast />
                {
                  (item.title || item.description) && <SiteBanner title={item.title} desc={item.description} />
                }
                <SiteNav />
              </S.Container>
            </S.Header>
          )
        })}
      </Slider>
    </div>
  )
}

export default Header;
