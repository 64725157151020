import React from 'react';
import Slider from 'react-slick';
import ClientCard from '../atoms/ClientCard';
import * as S from "../../../styles/GlobalStyles";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ClientCarousel = ({ clients }) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <div>
            <S.ContentTitle className='no-margin'>Principais Clientes</S.ContentTitle>
            <Slider {...settings}>
                {clients.map((item, index) => (
                    <ClientCard
                        className
                        key={index}
                        imageUrl={item.image}
                        name={item.client}
                        description={item.description}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default ClientCarousel;
