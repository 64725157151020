import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ClientsAPI = {
    getClients: async () => {
        try {
            const response = await axios.get(`${BASE_URL}/clients.php`);
            return response.data;
        } catch (error) {
            console.error('Error fetching clients:', error);
            return [];
        }
    },
};

export default ClientsAPI;
