import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SectionsAPI from '../../../utils/SectionsAPI';
import * as S from '../../../styles/GlobalStyles';

const SiteNav = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    // Fetch sections from the backend API
    const fetchSections = async () => {
      const sectionsData = await SectionsAPI.getAllSections();
      setSections(sectionsData);
    };

    fetchSections();
  }, []);

  return (
    <S.SiteNav>
      <S.SiteNavLeft>
        <Link to="/">Home</Link>
        <Link to="/videos">Vídeos</Link>
        {sections.map((section) => (
          <Link key={section.id} to={`/sections/${section.id}`}>{section.title}</Link>
        ))}
      </S.SiteNavLeft>
    </S.SiteNav>
  );
}

export default SiteNav;
