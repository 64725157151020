import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ArticlesAPI from '../../../utils/ArticlesAPI';
import Header from '../../design/organisms/Header';
import ReactMarkdown from 'react-markdown';
import icone from "../../../assets/images/whats-icon.png";
import * as S from "../../../styles/GlobalStyles";

function ArticleDetailPage() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [texto, setTexto] = useState("");
  const ancora = "https://api.whatsapp.com/send?phone=55015974065731&text="+texto;
  const BASE_URL = process.env.REACT_APP_BASE_URL_IMAGES;
  const banners = [{ filename: "64eca15242b17.jpg"}]

  useEffect(() => {
    // Fetch article details from the backend API based on the id in the URL
    const fetchArticleDetails = async () => {
      const articleData = await ArticlesAPI.getArticleById(id);
      setArticle(articleData);

    };

    fetchArticleDetails();
  }, [id]);

  useEffect(() => {
    !!article > 0 &&
    setTexto("Olá! Estava navegando no seu site e me interessei pelo item *"+article.title+"*. Gostaria de fazer uma cotação sem compromisso.")
  }, [article])

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header banner={banners} />

      <S.SiteMain>
        <S.Container>
          <S.Content>
            <S.PostFeatureFigure>
              <S.PostFeatureImage src={BASE_URL+article.coverImage} />
            </S.PostFeatureFigure>
            <S.PostFullContent>
              <S.ContentTitle>{article.title}</S.ContentTitle>
              <S.ContentBody>
                <ReactMarkdown>{article.content}</ReactMarkdown>
                <hr />
                <S.LinkWhatsApp className='button' href={ancora} target="_blank">
                  Entrar em contato
                  <S.IconeWhatsApp src={icone} />
                </S.LinkWhatsApp>
              </S.ContentBody>
            </S.PostFullContent>

          </S.Content>
        </S.Container>
      </S.SiteMain>

    </>
  );
}

export default ArticleDetailPage;
