import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const VideosAPI = {
  // Listar todos os vídeos
  getAllVideos: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/videos.php`);
      return response.data;
    } catch (error) {
      console.error('Error fetching videos:', error);
      return [];
    }
  },

  // Obter os detalhes de um vídeo específico por ID
  getVideoById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/videos.php/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching video details:', error);
      return null;
    }
  },

  // Outros métodos relacionados aos vídeos podem ser adicionados aqui, se necessário.
};

export default VideosAPI;
