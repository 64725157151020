import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ArticlesAPI = {
  // Listar todos os artigos
  getAllArticles: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/articles.php`);
      return response.data;
    } catch (error) {
      console.error('Error fetching articles:', error);
      return [];
    }
  },

  // Obter os detalhes de um artigo específico
  getArticleById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/articles.php/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching article details:', error);
      return null;
    }
  },

  // Listar os artigos por sectionId
  getArticlesBySectionId: async (sectionId) => {
    try {
      const response = await axios.get(`${BASE_URL}/articles_by_section.php?sectionId=${sectionId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching articles by sectionId:', error);
      return [];
    }
  },

  // Obter os artigos com paginação
  getArticlesWithPagination: async (page) => {
    try {
      const response = await axios.get(`${BASE_URL}/articles.php?page=${page}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching articles with pagination:', error);
      return [];
    }
  },

  // Listar os artigos por sectionId com paginação
  getArticlesBySectionIdWithPagination: async (sectionId, page) => {
    try {
      const response = await axios.get(`${BASE_URL}/articles_by_section.php?sectionId=${sectionId}&page=${page}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching articles by sectionId:', error);
      return [];
    }
  },
};

export default ArticlesAPI;
