import React, { useState, useEffect, useRef } from 'react';
import ArticlesAPI from '../../../utils/ArticlesAPI';
import * as S from '../../../styles/GlobalStyles';
import PostCard from '../../design/molecules/PostCard';
import Header from '../../design/organisms/Header';
import ClientCarousel from '../../design/molecules/ClientCarousel';
import ClientsAPI from '../../../utils/ClientsAPI';
import BannersAPI from '../../../utils/BannersAPI';

function HomePage() {
    const [articles, setArticles] = useState([]);
    const [clients, setClients] = useState([]);
    const [banners, setBanners] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const loadingRef = useRef(null);
    const BASE_URL = process.env.REACT_APP_BASE_URL_IMAGES;

    const fetchArticles = async () => {
        const articlesData = await ArticlesAPI.getArticlesWithPagination(currentPage);

        if (articlesData.length === 0) {
            setHasMoreItems(false);
        }

        setArticles(prevArticles => [...prevArticles, ...articlesData]);
    };

    const fetchClients = async () => {
        const clientsData = await ClientsAPI.getClients();

        if (clientsData.length === 0) {
            setHasMoreItems(false);
        }

        setClients(prevClients => [...prevClients, ...clientsData]);
    };

    const fetchBanners = async () => {
        const bannersData = await BannersAPI.getBanners();
        setBanners(bannersData);
    };

    useEffect(() => {
        fetchArticles();
        fetchClients();
        fetchBanners();
    }, [currentPage]);

    const handleScroll = () => {
        if (
            loadingRef.current &&
            window.innerHeight + window.scrollY >= loadingRef.current.offsetTop &&
            hasMoreItems
        ) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Header
                banner={banners}
            />

            <S.SiteMain>
                <S.Container>
                    <ClientCarousel clients={clients} />
                </S.Container>

                <S.Container>
                    <S.ContentTitle className='no-margin'>Artigos</S.ContentTitle>
                    <S.PostFeed>
                        {articles.map((article, index) => (
                            <div key={index}>
                                <PostCard
                                    imageUrl={BASE_URL + article.coverImage}
                                    title={article.title}
                                    content={article.content}
                                    publicationDate={article.publicationDate}
                                    link={`/post/${article.id}`}
                                />
                            </div>
                        ))}
                        {hasMoreItems && (
                            <div ref={loadingRef} className="loading-indicator">
                                Carregando...
                            </div>
                        )}
                    </S.PostFeed>
                </S.Container>
            </S.SiteMain>
        </>
    );
}

export default HomePage;
