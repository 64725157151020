import React from 'react';
import * as S from '../../../styles/GlobalStyles';

const ClientCard = ({ imageUrl, name, description, className }) => {

    const BASE_URL = process.env.REACT_APP_BASE_URL_IMAGES;
    return (
        <S.PostCard>
            <S.PostCardImage className={className ? "carrossel" : 'noclass'} imageUrl={BASE_URL+imageUrl} />
            <S.PostCardTitle>{name}</S.PostCardTitle>
            <S.PostFullContent>{description}</S.PostFullContent>
        </S.PostCard>
    );
};

export default ClientCard;
