import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const SectionsAPI = {
  // Listar todas as seções
  getAllSections: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/sections.php`);
      return response.data;
    } catch (error) {
      console.error('Error fetching sections:', error);
      return [];
    }
  },

  // Obter os detalhes de uma seção específica
  getSectionById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/sections.php/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching section details:', error);
      return null;
    }
  },
};

export default SectionsAPI;
