import styled, { css } from 'styled-components';

export const Viewport = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    *{
        font-family: 'Roboto', sans-serif;
    }
`;

export const Header = styled.header`
    background-position: 50%;
    background-size: cover;
    padding-bottom: 20px;
    padding-top: 20px;
    background-image: url(${(props) => props.bg});

    > div{
        margin: 0 auto 0 auto;
    }
`

export const Container = styled.div`
    margin: 0 auto 3rem auto;
    max-width: 1120px;
    padding: 0 4rem;

    .slick-slide{
        padding: 10px;
    }
`

export const SiteMast = styled.div`
    align-items: center;
    display: flex;
`

export const SiteBanner = styled.div`
    margin: 0 auto;
    max-width: 90%;
    padding: 7vw 0;
    text-align: center;
`

export const SiteNav = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;
`

export const SiteBannerTitle = styled.h1`
    color: #fff;
    font-size: 3.8rem;
    line-height: 1.3em;
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
`

export const SiteBannerDesc = styled.p`
    color: #fff;
    font-size: 1.7rem;
    line-height: 1.3em;
    margin: 5px 0 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
`

export const SiteNavLeft = styled.nav`
    margin: 0 20px 0 -10px;

    a{
        color: #fff;
        display: inline-block;
        opacity: .7;
        padding: 5px 10px;
        text-decoration: none;
    }
`

export const SiteMain = styled.main`
    padding: 2rem 0;
`

export const PostFeed = styled.section`
    grid-gap: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;

    a{
        &:hover{
            text-decoration: none;
        }
        text-decoration: none;
        color: inherit;
    }
`

export const VideoFeed = styled.section`
    display: flex;
    flex-wrap: wrap;

    div{
        width: 48%;
        margin: 5px;
        overflow: hidden;
    }
`

export const PostCard = styled.div`

`

export const PostCardHeader = styled.header`

`

export const PostCardImage = styled.div`
  background: #5b7a81 no-repeat center center;
  background-size: cover;
  height: 200px;
  margin: 0 0 10px;
  width: auto;
  border-radius: 5px;

  &.carrossel{
    background-size: 50%;
    background-color: #ffffff;
  }

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `}
`;

export const PostCardTitle = styled.h2`
    margin: 0 0 10px;
    padding: 0;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.15;
    margin-top: 0;
    text-rendering: optimizeLegibility;
    color: #15171a;
`

export const PostCardExcerpt = styled.section`
    line-height: 1.55em;
`

export const PostCardFooter = styled.footer`
    align-items: center;
    display: flex;
    color: #5b7a81;
    justify-content: space-between;
    margin: 0;
    font-size: 0.9rem;
`

export const PostCardFooterLeft = styled.div`
    align-items: center;
    display: flex;
`

export const PostCardFooterRight = styled.div`
    display: flex;
    flex-direction: column;
`

export const LinkWhatsApp = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DDD;
    color: #15171a;
    border-radius: 50px;
    max-width: 250px;
    padding: 5px 15px;
    margin: 20px auto;
    text-decoration: none;
    transition: all ease .4s;

    &:hover{
        background-color: #cbeafb;
    }
`

export const IconeWhatsApp = styled.img`
    width: 20px;
    margin-left: 10px;
`

export const Content = styled.article`
    font-size: 2rem;
    line-height: 1.7em;
    margin: 0 auto;

    img{
        max-width: 100%;
    }
`

export const PostFeatureFigure = styled.figure`

`

export const PostFeatureImage = styled.img`
    height: 500px;
    margin: 0 0 3vw;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
`

export const PostFullContent = styled.section`
    background: #fff;
    margin: 0 auto;
    max-width: 720px
`

export const ContentTitle = styled.h1`
    font-size: 3rem;
    margin: 0 0 0.8em;
    font-weight: 700;
    line-height: 1.15;

    &.no-margin{
        margin: 0;
        font-size: 2.4rem;
    }
`

export const ContentBody = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;

    hr{
        width: 100%;
        border: none;
        border-bottom: 1px solid #CCC;
    }

    a{
        &.button{
            background-color: #DDD;
            color: #15171a;
            border-radius: 50px;
            width: 250px;
            margin: 20px auto;
            text-decoration: none;
            transition: all ease .4s;

            &:hover{
                background-color: #cbeafb;
            }
        }
    }

    h1, h2, h3, h4, h5 {
        font-weight: 700;
        margin: 0.8em 0 0.4em;
    }

    h2 {
        font-size: 2.2rem;
    }

    h3 {
        font-size: 1.6rem;
    }

    p {
        line-height: 1.6;
        margin-bottom: 1rem;
    }

    li {
        line-height: 1.8;
        margin-bottom: .6rem;
    }

    ul, ol{
        margin-top: 2rem;
        margin-left: 2rem;
    }

    blockquote {
        border-left: 0.5em solid #cbeafb;
        margin: 0.3em 0 1.8em;
        padding: 0 1.6em;

        p{
            margin: 0;
        }
    }
`

export const Footer = styled.footer`
    background: #15171a;
    color: hsla(0,0%,100%,.7);
    font-size: .8rem;
    padding: 20px 0 40px;
`

export const SiteNavFooter = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: hsla(0,0%,100%,.7);

    a{
        color: hsla(0,0%,100%,.7);
    }
`

export const SiteLogo = styled.img`
    height: 60px;
`