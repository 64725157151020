import * as S from "../../../styles/GlobalStyles";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";


const SiteMast = () => {
    return (
        <S.SiteMast>
            <Link to="/">
                <S.SiteLogo src={logo} alt="Rodrigues SI" />
            </Link>
        </S.SiteMast>
    )
}

export default SiteMast;