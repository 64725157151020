import "./styles/reset.css";
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import ArticleDetailPage from './components/pages/ArticleDetailPage';
import Layout from './components/Layout';
import ArticlesList from "./components/pages/ArticlesList";
import VideosList from "./components/pages/Videos";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/videos" element={<VideosList />} />
          <Route path="/post/:id" element={<ArticleDetailPage />} />
          <Route path="/sections/:id" element={<ArticlesList />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
