import React from 'react';
import * as S from '../../../styles/GlobalStyles';
import parse from "html-react-parser";

const VideoCard = (props) => {
  // Limita o título do vídeo a um número máximo de caracteres
  const maxTitleLength = 40; // Altere o valor conforme necessário
  const limitedTitle = props.video.titulo.length > maxTitleLength
    ? `${props.video.titulo.substring(0, maxTitleLength)}...`
    : props.video.titulo;

  return (
    <>
      <S.PostCardHeader>
        <S.PostCardTitle title={props.video.titulo}>{limitedTitle}</S.PostCardTitle>
      </S.PostCardHeader>

      <S.PostCardExcerpt>
        {parse(props.video.url)}
      </S.PostCardExcerpt>
    </>
  );
};

export default VideoCard;
