import React from 'react';
import { Link } from 'react-router-dom';
import icone from "../../../assets/images/whats-icon.png";
import * as S from '../../../styles/GlobalStyles';

const removeMarkdown = (content) => {
  // Expressão regular para encontrar tags Markdown
  const regex = /(\*{1,2}|#{1,6}|`)(.*?)(\1)/g;

  // Substituir as tags Markdown por uma string vazia
  return content.replace(regex, '$2');
};

const PostCard = (props) => {
  const texto = "Olá! Estava navegando no seu site e me interessei pelo item *"+props.title+"*. Gostaria de fazer uma cotação sem compromisso.";
  const ancora = "https://api.whatsapp.com/send?phone=55015974065731&text="+texto;

  // Remove as tags Markdown do conteúdo
  const cleanContent = removeMarkdown(props.content);

  // Limita o conteúdo limpo a 150 caracteres
  const limitedContent = cleanContent.substring(0, 150);


  return (
    <>
      <Link to={props.link}>
        <S.PostCardHeader>
          <S.PostCardImage imageUrl={props.imageUrl} />
          <S.PostCardTitle>{props.title}</S.PostCardTitle>
        </S.PostCardHeader>

        <S.PostCardExcerpt>
          {limitedContent}
          {cleanContent.length > 150 && '... '}
          {cleanContent.length > 150 && <a href="#">Leia mais</a>}
        </S.PostCardExcerpt>
      </Link>
      <S.PostCardFooter>
        <S.PostCardFooterLeft>
          {props.publicationDate}
        </S.PostCardFooterLeft>

        <S.PostCardFooterRight>
          <S.LinkWhatsApp href={ancora} target="_blank">
            Entrar em contato
            <S.IconeWhatsApp src={icone} />
          </S.LinkWhatsApp>
        </S.PostCardFooterRight>

      </S.PostCardFooter>
    </>
  );
};

export default PostCard;
