import React, { useState, useEffect } from 'react';
import VideosAPI from '../../../utils/VideosAPI'; 
import * as S from '../../../styles/GlobalStyles';
import VideoCard from '../../design/molecules/VideoCard'; 
import Header from '../../design/organisms/Header';

function VideosList() {
  const [videos, setVideos] = useState([]);
  const banners = [{ filename: "64eca15242b17.jpg"}];

  const fetchVideos = async () => {
    try {
      const videosData = await VideosAPI.getAllVideos();
      setVideos(videosData);

    } catch (error) {
      console.error('Erro ao buscar vídeos:', error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <>
      <Header banner={banners} />

      <S.SiteMain>
        <S.Container>

          <S.VideoFeed>
            {videos.map((video, index) => (
              <div key={index}>
                <VideoCard
                  video={video} 
                />
              </div>
            ))}
          </S.VideoFeed>
        </S.Container>
      </S.SiteMain>
    </>
  );
}

export default VideosList;
