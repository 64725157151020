import * as S from "../../../styles/GlobalStyles";
import SiteBannerDesc from "../atoms/SiteBannerDesc";
import SiteBannerTitle from "../atoms/SiteBannerTitle";


const SiteBanner = (props) => {
    return (
        <S.SiteBanner>
            <SiteBannerTitle title={props.title} />
            <SiteBannerDesc desc={props.desc} />
        </S.SiteBanner>
    )
}

export default SiteBanner;